// This file was generated during build from environment variables.
export const apiEndpoint = "https://dev.pep-ge2c-api.careevolution.com";
export const oauthClientId = "GE2C";
export const oauthScopes = ["openid","email","profile","offline_access","ge2c"];
export const oauthURLs = {
  "authorize": "https://designer.mydatahelps.dev/identityserver/connect/authorize",
  "token": "https://designer.mydatahelps.dev/identityserver/connect/token",
  "signout": "https://designer.mydatahelps.dev/identityserver/connect/endsession"
};
export const validationsEnabled = false;
